#troo-da-who-we-are-section {
  padding-top: 100px;
  position: relative;
  padding-bottom: 100px;
  background-image: url(../../Images/who-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.dot-img {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
  position: absolute;
  z-index: -9;
  left: 14%;
  top: 9%;
}
@keyframes mover {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
.who-img img {
  width: 100%;
}
.who-text {
  padding-left: 18px;
}
.sub-txt span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #0fa00f;
  border-radius: 100px;
  padding: 6px 16px 6px 25px;
  position: relative;
  margin-bottom: 23px;
  display: inline-block;
}
.sub-txt span:before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #fff;
  content: "";
  border-radius: 100px;
  top: 11px;
  left: 11px;
}
.who-text strong {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #232323;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 4px;
}
.expert-team {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.ex-tm .strong {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #232323;
  display: block;
  margin-bottom: 2px;
}
.ex-tm .strong .counternum {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #232323;
}
.ex-tm span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #232323;
  white-space: pre;
}
.ex-dest {
  border-left: 1px solid #0fa00f;
  margin-left: 39px;
  padding-right: 0;
  padding-left: 39px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.who-text h4 {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .who-text {
    padding-left: 0;
    padding-top: 50px;
  }
}
@media (max-width: 540px) {
  #troo-da-who-we-are-section .container {
    padding: 0 20px;
  }
  .ex-dest {
    margin-left: 15px;
    padding-left: 15px;
  }
  #troo-da-who-we-are-section{
    padding: 50px 0;
  }
}
