section#troo-da-faq-section .sub-txt {
  text-align: center;
}
section#troo-da-faq-section {
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px;
}
section#troo-da-faq-section:after {
  background-image: url(../../Images/faq-bg.png);
  position: absolute;
  content: "";
  width: 100%;
  height: 1146px;
  top: -376px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 40% 143%;
  z-index: -9;
}
button.accordion-button.collapsed {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(149 157 165 / 10%);
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 18px 38px;
  overflow: hidden;
}
.accordion-button:not(.collapsed) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #0fa00f !important;
  background: #f3faf3 !important;
  box-shadow: none !important;
  border-radius: 5px;
  padding: 18px 38px;
  overflow: hidden;
}
.accordion-button:not(.collapsed)::before {
  background: #0fa00f;
}
.accordion-body {
  background: #f3faf3;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #232323;
  padding: 24px 83px 24px 34px !important;
}
.accordion-item {
  border: none !important;
  margin-bottom: 20px;
}
.accordion {
  margin-top: 43px;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../../Images/green-icon.png) !important;
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(../../Images/black-icon.png) !important;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-button::before {
  background: #232323;
  opacity: 0.1;
  position: absolute;
  width: 60px;
  height: 60px;
  content: "";
  border-radius: 50%;
  left: -28px;
  z-index: 1;
  top: -39px;
}
.col-detals {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dotted #fff;
  margin: 4px 2px;
  padding: 29px 27px;
}
.cll-ss {
  background: #0fa00f;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
  border-radius: 5px;
  padding: 6px;
  margin-top: 50px;
}
.call-deails {
  display: flex;
}
.cal-te span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: block;
}
.cal-te a {
  text-decoration: none;
}
.cal-te strong {
  color: #fff;
}
.icon-cal i {
  color: #ffff;
}
.cal-te {
  margin-left: 11px;
}
.quistions-section h3 {
  color: #0fa00f;
}
.quistions-section {
  margin-top: 50px;
}

@media (max-width: 991px) {
  .col-detals {
    flex-wrap: wrap;
  }
  .call-deails {
    padding-bottom: 14px;
  }
  .call-deails:last-child {
    padding-bottom: 0px;
  }
}
@media (max-width: 767px) {
  section#troo-da-faq-section {
    padding-top: 60px;
    padding-bottom: 50px;
  }
}

@media (max-width: 540px) {
  section#troo-da-faq-section .container{
    padding: 0 20px;
  }
}