@import url("https://fonts.googleapis.com/css2?family=Jost&family=Lato:wght@400;700&family=Montserrat:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat";
}
p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #232323;
}

body h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  color: #ffffff;
}

body h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #232323;
}
body h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #232323;
}
body h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #232323;
}

body h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #191825;
}

.read-cta:hover img {
  transform: translateX(50%);
}
.read-cta a img {
  transition: 0.5s;
  margin-left: 10px;
}
.read-cta a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #232323;
  padding: 16px 22px;
  text-decoration: none;
  border: 1px solid #232323;
  border-radius: 100px;
  transition: 0.5s;
}
.read-cta a:hover {
  color: #fff;
  background: #0fa00f;
  border: 1px solid #0fa00f;
}
.read-cta:hover img {
  filter: brightness(18);
}
.read-cta {
  display: block;
  margin-top: 28px;
}
.read-cta.green-btn:hover img {
  transform: translateX(50%);
}
.read-cta.green-btn a img {
  transition: 0.5s;
  margin-left: 10px;
}
.read-cta.green-btn a {
  border: none;
  background: #0fa00f;
  border-radius: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  padding: 16px 22px;
  text-decoration: none;
}
.read-cta.green-btn a:hover {
  color: #fff;
}
.get-col.active a {
  background: #0fa00f;
  border-radius: 100px;
  border: none;
}

@media (max-width: 991px) {
  body h1 {
    font-size: 35px;
    line-height: 50px;
  }
}
