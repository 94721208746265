#troo-da-pricing-tabble-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url(../../Images/pricing-bg.png);
  background-position: 45%;
}
.pricing #troo-da-pricing-tabble-section {
  background-image: none;
  padding-top: 100px;
}
section#troo-da-pricing-tabble-section .row {
  align-items: center;
}
.pricing-col {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 10%);
  border-radius: 5px;
  padding-left: 30px;
  padding-top: 43px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-top: 59px;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #fff;
}
.pricing-col:after {
  background: #0fa00f;
  opacity: 0;
  content: "";
  position: absolute;
  width: 100%;
  height: 227px;
  bottom: -109px;
  border-radius: 100%;
  left: 0;
  transition: 0.5s;
}
.pricing-col:hover {
  background: #ffffff;
  border-bottom: 1px solid #0fa00f;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.1);
  border-radius: 5px;
}
.pricing-col:hover:after {
  background: #0fa00f;
  opacity: 0.1;
}
.pricing-tile h4 {
  font-weight: 400;
}
.pri-txt {
  border-bottom: 1px dashed rgb(35 35 35 / 50%);
  margin-bottom: 49px;
  padding-bottom: 33px;
  transition: 0.5s;
}
.pricing-col:hover .pri-txt {
  border-bottom: 1px dashed rgb(15 160 15 / 50%);
}
.princing-btn {
  margin-top: 50px;
  position: relative;
  z-index: 9;
}
.pric-img img {
  filter: gray;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.5s;
}
.pricing-col:hover .pric-img img {
  filter: gray;
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
}
.princing-btn button {
  background: #232323;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 10%);
  border-radius: 1000px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  transition: 0.5s;
  border: none;
  padding: 11px 20px;
  max-width: 330px;
  width: 100%;
}
.pricing-col:hover .princing-btn button {
  background-color: #0fa00f;
}
.pricing-tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}
.pri-tt strong {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #232323;
  display: inline-block;
  margin-top: 17px;
  transition: 0.5s;
}
.pricing-col:hover .pri-tt strong {
  color: #0fa00f;
}
.pri-tt span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
  color: #232323;
  transition: 0.5s;
}
.pricing-vale ul li span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232323;
  margin-left: 8px;
}
.pricing-vale ul li {
  list-style: none;
  padding-bottom: 22px;
  display: flex;
  align-items: center;
}
.pricing-vale ul li:last-child {
  padding-bottom: 0px;
}
.pricing-vale ul {
  padding: 0;
  margin: 0;
}
.all-prcinng {
  margin-top: 48px !important;
  text-align: center;
}
.all-prcinng a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #0fa00f;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  border-bottom: 1px solid;
  margin: 0 auto;
}
.pricing-col li.disable span {
  color: #232323;
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .princing-btn button {
    padding: 11px 20px;
  }
}

@media (max-width: 991px) {
  .pri-tt strong {
    font-size: 22px;
  }
  .pri-tt span {
    font-size: 14px;
  }
  .princing-btn button {
    padding: 11px 22px;
    font-size: 15px;
  }
  .pricing-tile {
    flex-wrap: wrap;
  }
  .pricing-tile h4 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .pri-tt strong {
    font-size: 27px;
  }
  section#troo-da-pricing-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .princing-btn button {
    max-width: 230px;
  }
  #troo-da-pricing-tabble-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 540px) {
  #troo-da-pricing-tabble-section .container{
    padding: 0 20px;
  }
}
