section#troo-da-team-section .sub-txt {
  text-align: center;
  margin-bottom: 60px;
}
section#troo-da-team-section {
  background-image: url(../../Images/team-bg.png);
  padding-top: 100px;
  background-repeat: no-repeat;
  background-color: #f3faf3;
  padding-bottom: 244px;
}
.team-col {
  position: relative;
  cursor: pointer;
}
.team-deatils ul li {
  display: inline-block;
}
.team-deatils ul {
  padding: 0;
}
.team-deatils ul li a svg {
  color: #232323;
}
.team-deatils ul li a {
  padding: 3px 9px;
}
.team-col .team-deatils ul li a:hover {
  background: #0fa00f;
  border-radius: 5px;
  color: #fff;
}
.team-deatils ul li a:hover svg {
  color: #fff;
}
.team-deatils {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: absolute;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  bottom: 90px;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 99;
  border-radius: 5px;
}
.team-col:hover .team-deatils {
  padding-top: 10px;
  opacity: 1;
}
.team-col:hover .team-deatils {
  opacity: 1;
}
.team-txt h3 {
  margin-bottom: 10px;
}
.team-img img {
  width: 100%;
}
.team-txt {
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 10%);
  border-radius: 5px;
  z-index: 9;
  position: relative;
  margin: 0 30px;
  padding: 24px 0;
  top: -91px;
}
.team-col:hover .team-txt {
  border-bottom: 1px solid #0fa00f;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 10%);
  border-radius: 5px;
}
#troo-da-team-section .read-cta {
  text-align: center;
}

/* team Component2 */
section#troo-da-meet-team-section {
  padding-top: 100px;
}

#troo-da-meet-team-section .sub-txt {
  text-align: center;
  margin-bottom: 60px;
}
#troo-da-meet-team-section .team-img img {
  width: 100%;
  filter: gray;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
#troo-da-meet-team-section .team-col:hover .team-img img {
  filter: gray;
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
}

@media (max-width: 1024px) {
  .team-txt h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .team-txt {
    margin: 0;
    padding: 16px 0;
    top: -91px;
  }
  .team-txt h3 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  section#troo-da-meet-team-section {
    padding-top: 50px;
  }
  section#troo-da-team-section {
    padding-bottom: 100px;
    padding-top: 50px;
  }
  .team-txt {
    top: -40px;
  }
  .team-deatils {
    bottom: 35px;
}

}

@media (max-width: 540px) {
  section#troo-da-team-section .container {
    padding: 0 20px;
  }
  section#troo-da-meet-team-section .container {
    padding: 0 20px;
  }
}
