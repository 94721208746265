#troo-da-review-section {
  padding-bottom: 45px;
}
.bg-reviw {
  background: #0fa00f;
  border-radius: 5px;
  width: 100%;
  height: 477px;
  margin: 0 auto;
  display: block;
  max-width: 1720px;
  text-align: center;
  margin-top: -135px;
  position: absolute;
  left: 0;
  right: 0;
}
.bg-reviw span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 200px;
  line-height: 36px;
  opacity: 0.2;
  color: #0fa00f;
  position: absolute;
  left: 0;
  right: 0;
  top: 136px;
  -webkit-text-stroke: 1px #fff;
}
.revi-text {
  position: relative;
}
.revi-text p {
  color: #fff;
}
#troo-da-review-section .sub-txt {
  position: relative;
}
#troo-da-review-section .sub-txt span {
  background-color: #fff;
  color: #0fa00f;
}
#troo-da-review-section .sub-txt span:before {
  background-color: #0fa00f;
}
#troo-da-review-section .sub-txt h2 {
  color: #fff;
}
.clinet-section {
  position: relative;
  margin-top: 47px !important;
}
.client-col {
  transition: 0.5s;
  border-bottom: 1px solid #fff;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 10%);
  border-radius: 5px;
  padding: 44px 37px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  position: relative;
}
.client-col:after {
  width: 100%;
  height: 112px;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -2%;
  max-width: 469px;
  border-radius: 0 0 100% 100%;
  z-index: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  opacity: 0;
  z-index: -1;
}
.client-col:hover:after {
  background: #0fa00f;
  opacity: 0.1;
}
.client-col:hover {
  background: #ffffff;
  border-bottom: 1px solid #0fa00f;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 10%);
  border-radius: 5px;
}
.client-img {
  position: relative;
  margin-bottom: 15px;
}
.cli-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #232323;
  width: 30px;
  height: 30px;
  border: 2px solid #232323;
  border-radius: 100px;
  position: absolute;
  left: -70px;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  background: #fff;
  transition: 0.5s;
}
.client-col:hover .cli-text {
  border: 2px solid #0fa00f;
  color: #0fa00f;
}
.client-text span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  color: #232323;
  display: block;
  margin-bottom: 37px;
}
.client-text h4 {
  margin-bottom: 0;
  transition: 0.5s;
}
.client-col:hover h4 {
  color: #0fa00f;
}
.client-text strong {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #232323;
  margin-bottom: 20px;
  display: block;
}
.client-img img {
  border: 2px solid #232323;
  border-radius: 100px;
  transition: 0.5s;
}
.client-col:hover .client-img img {
  border: 2px solid #0fa00f;
}
.rw-botm-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #232323;
  margin-top: 48px;
  margin-bottom: 100px;
}
.rw-botm-text a {
  font-weight: 700;
  color: #0fa00f !important;
  display: inline-block;
  line-height: 28px;
  text-decoration: none;
  border-bottom: 1px solid #0fa00f !important;
}

/* testimonial */
#troo-da-testimonial-section .bg-reviw {
  background: transparent;
  z-index: -9;
}
#troo-da-testimonial-section .bg-reviw span {
  opacity: 0.2;
  color: #fff;
  -webkit-text-stroke: 1px #0fa00f;
}
#troo-da-testimonial-section .revi-text p {
  color: #232323;
}
#troo-da-testimonial-section {
  padding-bottom: 100px;
  padding-top: 100px;
}

@media (max-width: 1800px) {
  .bg-reviw span {
    font-size: 150px;
    top: 85px;
  }
}
@media (max-width: 1400px) {
  .bg-reviw span {
    font-size: 100px;
  }
}

@media (max-width: 991px) {
  .bg-reviw span {
    font-size: 46px;
  }
  .client-col {
    margin-top: 40px;
    padding: 12px 9px;
  }
}
@media (max-width: 767px) {
  .bg-reviw span {
    font-size: 40px;
  }
  .client-col {
    margin-top: 40px;
  }
  #troo-da-testimonial-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .bg-reviw {
    margin-top: -46px;
  }
  .client-col {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .rw-botm-text {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #troo-da-review-section {
    padding-bottom: 25px;
  }
}

@media (max-width: 540px) {
  .troo-da-review-section .container{
    padding: 0 20px;
  }
  #troo-da-testimonial-section .container{
    padding: 0 20px;
  }
}